<template>
  <div class="rounded-sm bg-white p-4 mb-4">
    <p class="font-size-sm font-weight-bold mb-4">
      {{ $t('sparkmembers.members-details.enrollment-details.access-history.components.activities.title') }}
    </p>
    <b-table :fields="fields" :items="activities" small bordered responsive>
      <template #cell(updated_at)="data">
        <div class="text">
          {{ data.item.updated_at_formatted }}
        </div>
      </template>
      <template #cell(title)="data">
        <div class="text">
          {{ data.item.title }}
        </div>
      </template>
      <template #cell(exam_answers.grade)="data">
        <div class="text text-center">
          {{ data.item.exam_answers.grade }}
        </div>
      </template>
      <template slot="bottom-row">
        <b-td colspan="2" variant="secondary" class="text-right">
          Média das notas
        </b-td>
        <b-td colspan="1" variant="secondary" class="text-center">
          {{ gradeAverage }}
        </b-td>
      </template>
    </b-table>
  </div>
</template>
<script>
export default {
  name: 'Activities',
  props: {
    activities: Array,
  },
  data() {
    return {
      fields: [
        {
          key: 'updated_at',
          label: this.$t(
            'sparkmembers.members-details.enrollment-details.access-history.components.activities.table.updated-at'
          ),
          tdClass: 'updated-at',
        },
        {
          key: 'title',
          label: this.$t(
            'sparkmembers.members-details.enrollment-details.access-history.components.activities.table.title'
          ),
        },
        {
          key: 'exam_answers.grade',
          label: this.$t(
            'sparkmembers.members-details.enrollment-details.access-history.components.activities.table.grade'
          ),
        },
      ],
    };
  },
  computed: {
    gradeAverage() {
      const totalGrades = this.activities.reduce((sum, activity) => {
        const grade = activity.exam_answers?.grade || 0;
        return sum + grade;
      }, 0);
      const numberOfGrades = this.activities.length;
      return numberOfGrades ? (totalGrades / numberOfGrades).toFixed(2) : '-';
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep {
  .table {
    border-collapse: inherit;
    border-spacing: 0;
    @media (max-width: 767px) {
      width: 100vw;
      overflow: scroll;
    }
  }

  .updated-at {
    width: 160px;
  }

  .table thead th {
    background-color: #e3e3e3;
    border-color: #cfcfcf;
    font-weight: bold;
    text-align: center;
    color: #262626;
    padding: 8px;
    font-size: 14px;
    text-transform: initial;
    text-wrap: nowrap;
  }

  .table tbody td {
    border-color: #cfcfcf;
    padding: 16px 8px;
    word-break: break-all;

    &:first-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &:last-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  @media (min-width: 768px) {
    .table thead tr,
    .table tbody tr {
      box-shadow: none;
    }
  }
}
</style>
